.icons {
	&__wrapper {
		width: 100%;
		position: relative;
	}
	&__image{
		&--item{
			height: 300px;
			width: 100%;
			position: relative;
			margin: 10px 0;
			@media screen and (max-width: 768px){
				img{
					object-fit: contain;
				}
			}
			&:before{
				content: '';
				left: 50%;
				top: 50%;
				border: 2px solid var(--color-theme-primary);
				position: absolute;
				width: calc(90% + 2px);
				height: calc(90% + 2px);
				opacity: 1;
				transform: translate(-50%, -50%);
			}
			img{
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
		&--title{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			z-index: 2;
			width: 85%;
			h4{
				font-weight: 700;
				color: #fff;
				text-shadow: 4px 2px 2px rgb(0 0 0 / 30%);
			}
			h5{
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
}
