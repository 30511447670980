.textwithphoto {
	&__text {
		padding: 50px 30px;
		background-color: #fff;
		box-shadow: -2px 2px 7px rgb(0 0 0 / 11%);
		position: relative;
		left: 40px;
		z-index: 2;
		top: 20px;

		.title {
			h3 {
				font-size: 28px;
				color: #202020;
			}

			h4 {
				font-size: 14px;
				color: #202020;
			}
		}
	}

	&__image {
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		&:after {
			content: '';
			left: -20px;
			top: -20px;
			background: var(--color-theme-primary);
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	&__content {
		text-align: left;
		color: #202020;

		a {
			color: var(--color-theme-primary) !important;
		}

		ul {
			margin: 0 25px;
		}
	}

	&__wrapper {
		&.reverse {
			.row {
				flex-direction: row-reverse;

				.textwithphoto__text {
					left: unset;
					right: 40px;
				}
			}
		}
	}
}