.footer {
	position: relative;
	padding: 50px 0 60px;
	color: #fff;
	background: var(--color-theme-secondary);
	z-index: 0;

	.credits {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		background: #202020;
		padding: 10px;
		font-size: 14px;
		text-align: center;
		z-index: 99;
		color: #ffffff !important;
	}

	.title {
		h4 {
			color: #fff;
			font-size: 20px;
			margin: 5px 0;
		}
	}

	.desc {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__booking {
		color: #fff;
		border: 2px solid #fff;
		padding: 15px 25px;
		letter-spacing: 2px;
		font-weight: 400;
		text-transform: uppercase;
		transition: all .4s ease-in-out;

		&:hover {
			color: var(--color-theme-primary);
			border-color: var(--color-theme-primary);
		}
	}

	&__wrapper {
		padding: 0;

		&--link {
			padding: 0;
			margin: 0;
			margin-bottom: 3px;
			text-align: right;
		}
	}

	&__link {
		font-size: 16px;
		letter-spacing: 1px;
		margin: 4px 0;
		font-weight: 400;
		position: relative;
		transition: all .3s ease-in;
		z-index: 9;
		white-space: nowrap;

		&:hover {
			color: var(--color-theme-primary) !important;
		}

		i {
			font-size: 16px;
			margin-right: 5px;
			text-transform: none;
			line-height: 17px;
			position: absolute;
			left: -20px;
			top: 2px;
		}
	}

	&__item {
		b {
			font-weight: bolder;
		}
	}

	&__logo {
		width: 270px;
		height: 270px;
		
		img {
			max-width: 100%;
			width: 100%;
			height: 100%;
			object-fit: contain;
			filter: brightness(0) invert(1);
		}
	}
}