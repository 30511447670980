@charset "UTF-8";

@import 'standard2/components/header.scss';
@import 'standard2/components/footer.scss';

@import 'standard2/modules/slider.scss';
@import 'standard2/modules/hero.scss';
@import 'standard2/modules/gallery.scss';
@import 'standard2/modules/equipment.scss';
@import 'standard/modules/photobackground.scss';
@import 'standard2/modules/textwithphoto.scss';
@import 'standard2/modules/contactform.scss';
@import 'standard2/modules/text.scss';
@import 'standard2/modules/icons.scss';
@import 'standard2/modules/textwithcontact.scss';

@import 'standard2/mobile.scss';

:root {
	--font-theme-primary: 'Raleway';
	--font-theme-secondary: 'Trajan-Pro-3';
}

/* raleway-200 - latin-ext */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 200;
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-200.eot');
	/* IE9 Compat Modes */
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-200.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-200.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-200.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-200.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-200.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* raleway-regular - latin-ext */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.eot');
	/* IE9 Compat Modes */
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-regular.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* raleway-600 - latin-ext */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-600.eot');
	/* IE9 Compat Modes */
	src: url('/assets/fonts/raleway/raleway-v28-latin-ext-600.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-600.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-600.woff') format('woff'),
		/* Modern Browsers */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-600.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/assets/fonts/raleway/raleway-v28-latin-ext-600.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* playfair-display-regular - latin-ext */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Trajan-Pro-3';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/trajan/Trajan-Pro-3-Regular.otf') format("opentype");
}


body {
	font-family: var(--font-theme-primary);
	color: var(--color-theme-font-primary);
	-webkit-font-feature-settings: "lnum";
	-moz-font-feature-settings: "lnum";
	-ms-font-feature-settings: "lnum";
	-o-font-feature-settings: "lnum";
	font-feature-settings: "lnum";
}

.title {
	position: relative;
	margin-top: 0px;
	margin-bottom: 25px;

	h1,
	h3 {
		font-size: 32px;
		text-align: left;
		font-weight: 400;
		margin-top: 0;
		margin-bottom: 10px;
		position: relative;
		font-family: var(--font-theme-secondary);
		letter-spacing: 3px;
	}

	h2,
	h4 {
		position: relative;
		font-size: 16px;
		text-align: left;
		text-transform: capitalize;
		letter-spacing: 6px;
		font-weight: 400;
		font-family: var(--font-theme-secondary);
		color: var(--color-theme-primary);
	}
}

section {
	ul{
		padding-left: 2rem;
		li {
			position: relative;
	
			&:before {
				content: 'done';
				font-family: Material Icons;
				color: var(--color-theme-secondary);
				left: -25px;
				position: absolute;
			}
		}
	}
}

.custom-shape-top {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
	transform: rotate(180deg);
	z-index: 8;

	svg {
		position: relative;
		display: block;
		width: calc(100% + 1.3px);
		height: 50px;
	}

	path {
		fill: var(--color-theme-background-primary);
	}
}

.custom-shape-bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
	z-index: 8;

	svg {
		position: relative;
		display: block;
		width: calc(100% + 1.3px);
		height: 50px;
	}

	path {
		fill: var(--color-theme-background-primary);
	}
}

table {

	background-color: #fff;
	border: 1px solid #ccc;
	font-size: 14px;
	overflow-x: auto;

	thead {
		color: #fff;

		tr {
			background: unset;
			background: #444;

			td {
				padding: 20px;
				vertical-align: middle;
				border: 1px solid #ccc;
			}
		}
	}

	tbody {
		width: 100% !important;

		tr {
			border: 1px solid #ccc;

			&:nth-of-type(odd) {
				background-color: #f4f4f4;
			}

			td {
				padding: 20px;
				border: 1px solid #ccc;
				vertical-align: middle;
			}
		}
	}
}