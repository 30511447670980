[data-module="text"] {
	position: relative;
}

.text {
	&__content {
		text-align: left;
		margin: 0 auto;

		a {
			color: var(--color-theme-primary) !important;
		}

		ul {
			margin: 0 25px;
		}
		iframe {
			width: 100%;
			height: 600px;
		}
	}
}