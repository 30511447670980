.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	transition: all 0.2s ease-in;
	background-color: var(--color-theme-background-primary);
	z-index: 999;

	&__logo {
		position: relative;
		width: 250px;
		height: 100px;
		max-height: 100px;
		margin: 0 auto;
		transition: all .4s ease-in-out;
		z-index: 10;

		img {
			max-width: 100%;
			width: 100%;
			height: 100%;
			padding: 5px;
			object-fit: contain;
			transition: all .4s ease-in-out;
		}
	}

	&__booking {
		position: relative;
		color: var(--color-theme-secondary) !important;
		border: 2px solid var(--color-theme-secondary);
		padding: 15px 25px;
		letter-spacing: 2px;
		font-weight: 400;
		text-transform: uppercase;
		transition: all .4s ease-in-out;
		z-index: 10;

		&:hover {
			color: var(--color-theme-primary) !important;
			border-color: var(--color-theme-primary);
		}
	}

	&__scroll {
		top: 0;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

		.menu__link {
			color: var(--color-theme-font-primary);
		}

		.header__logo {
			height: 80px;
			max-height: 80px;

			img {
				padding: 10px;
			}
		}

		.menu {
			&__hamburger {

				&--bar1,
				&--bar2,
				&--bar3 {
					background-color: var(--color-theme-secondary);
				}

				&.active {

					.menu__hamburger--bar1,
					.menu__hamburger--bar2,
					.menu__hamburger--bar3 {
						background-color: var(--color-theme-secondary);
					}
				}
			}

			&__link {
				color: var(--color-theme-font-primary);
			}
		}

		.header__booking {
			&:hover {
				color: var(--color-theme-primary) !important;
				border-color: var(--color-theme-primary);
			}
		}
	}

	.menu {
		display: flex;
		align-items: center;
		position: fixed;
		background: var(--color-theme-background-primary);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		justify-content: center;
		visibility: hidden;
		opacity: 0;
		transition: all .4s ease-in-out;

		&.active {
			visibility: visible;
			opacity: .9;
		}

		&__wrapper {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 15px;
			margin: 0;
		}

		&__item {
			overflow: hidden;
			padding-bottom: 10px;
			padding-top: 10px;
			position: relative;
		}

		&__link {
			font-size: 20px;
			text-transform: uppercase;
			letter-spacing: 2px;
			color: var(--color-theme-font-primary);
			font-weight: 400;
			&.current {
				color: var(--color-theme-primary) !important;
			}
			&:after {
				content: '';
				left: 0;
				bottom: 0px;
				position: absolute;
				height: 2px;
				background: var(--color-theme-primary);
				width: 100%;
				transform: translateX(-105%);
				transition: all .4s ease-in-out;
			}

			&:hover {
				&:after {
					transform: translateX(0);
				}
			}
		}

		&__hamburger {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 6px;
			z-index: 10;
			cursor: pointer;

			&:hover {
				.menu__hamburger--bar1 {
					background-color: var(--color-theme-primary);
				}

				.menu__hamburger--bar2 {
					background-color: var(--color-theme-primary);
				}

				.menu__hamburger--bar3 {
					background-color: var(--color-theme-primary);
				}
			}

			&--bar1,
			&--bar2,
			&--bar3 {
				width: 30px;
				height: 4px;
				background-color: var(--color-theme-secondary);
				transition: .4s;
			}

			span {
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 2px;
				line-height: 15px;
				transition: all .3s ease-in-out;
			}

			&.active {
				.menu__hamburger--bar1 {
					transform: rotate(-45deg) translate(-7px, 7px);
				}

				.menu__hamburger--bar2 {
					opacity: 0;
				}

				.menu__hamburger--bar3 {
					transform: rotate(45deg) translate(-7px, -7px);
				}

				span {
					color: var(--color-theme-primary);
				}
			}

			&:hover {
				span {
					color: var(--color-theme-primary);
				}
			}
		}

		&__phone {
			position: relative;
			font-family: 'Material Icons';
			font-size: 32px;
			z-index: 10;
			color: var(--color-theme-secondary);
			transition: color .3s;

			&:hover {
				color: var(--color-theme-primary);
			}
		}
	}
}