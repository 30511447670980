.gallery {
	&__wrapper {
		width: 100%;
		position: relative;

	}

	&__container {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
		grid-auto-rows: 280px;
		grid-auto-flow: dense;
	}

	&__image {
		overflow: hidden;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2em;
		overflow: hidden;

		&:after {
			content: "";
			background: linear-gradient(transparent, black);
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transform: translateY(100%);
			transition-duration: .75s;
		}

		&:hover {
			&:after {
				transform: translateY(50%);
			}

			&--item {
				transform: scale(1.05);
			}
		}

		&--item {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition-duration: 1s;
		}

		&.last {
			&:after {
				transform: none;
				opacity: .8;
				background: var(--color-theme-primary);
			}

			&:before {
				content: attr(data-count);
				color: #fff;
				top: 50%;
				left: 50%;
				z-index: 1;
				transform: translate(-50%, -50%);
				position: absolute;
			}
		}
	}
}