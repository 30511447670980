[data-module="hero"] {
	padding: 0px !important;
}

.hero {
	&__wrapper {
		height: 80vh;
		width: 100%;
		position: relative;
		overflow: hidden;

		&:after {
			content: '';
			left: 0;
			top: 0;
			background-color: rgba(#000, .3);
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: 1;
		}

		&:before {
			content: '';
			left: 0;
			top: 0;
			background: linear-gradient(0deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, .6) 100%);
			height: 160px;
			width: 100%;
			position: absolute;
			z-index: 2;
		}
	}

	&__image {
		height: 80vh;
		width: 100%;
		overflow: hidden;

		&--item {
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	&__text.title {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		margin: 0 auto;

		h1 {
			font-size: 70px;
			color: #fff;
			font-weight: 500;
			text-shadow: 2px 2px 5px #000000;
		}

		h2 {
			color: #fff;
			font-size: 22px;
		}
	}
}