/*region Ikony do list atrybutów*/
@font-face {
  font-family: "icomoon";
  src: url("/assets/images/font/icomoon.eot?c5m6ej");
  src: url("/assets/images/font/icomoon.eot?c5m6ej#iefix")
      format("embedded-opentype"),
    url("/assets/images/font/icomoon.ttf?c5m6ej") format("truetype"),
    url("/assets/images/font/icomoon.woff?c5m6ej") format("woff"),
    url("/assets/images/font/icomoon.svg?c5m6ej#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconeq-"],
[class*=" iconeq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  float: left;
  font-size: 1.4em;
  margin-right: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eq__icon {
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  filter: invert(40%) sepia(66%) saturate(883%) hue-rotate(167deg)
    brightness(97%) contrast(87%);
}

.equip {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 75%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .eq__wrapper {
    min-width: 200px;
    column-gap: 15px;
    display: flex;
    flex-wrap: wrap;
    .eq__text {
      white-space: nowrap;
      font-size: 15px;
    }
  }
  &__name {
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.iconeq-boisko-01:before {
  content: "\20";
}
.iconeq-caloroczny-01:before {
  content: "\e900";
}
.iconeq-iron-01:before {
  content: "\e901";
}
.iconeq-disabled-01:before {
  content: "\e902";
}
.iconeq-spa-01:before {
  content: "\e904";
}
.iconeq-sala_zabaw-01:before {
  content: "\e905";
}
.iconeq-karta-01:before {
  content: "\e906";
}
.iconeq-balkon-01-01:before {
  content: "\e907";
}
.iconeq-basen-01:before {
  content: "\e908";
}
.iconeq-bilard-01:before {
  content: "\e909";
}
.iconeq-czajnik-01:before {
  content: "\e90a";
}
.iconeq-dla_dzieci-01:before {
  content: "\e90b";
}
.iconeq-grill-01:before {
  content: "\e90c";
}
.iconeq-klimatyzacja-01:before {
  content: "\e90d";
}
.iconeq-kuchnia-01:before {
  content: "\e90e";
}
.iconeq-lazienka-01:before {
  content: "\e90f";
}
.iconeq-lodowka-01:before {
  content: "\e910";
}
.iconeq-obiad-01:before {
  content: "\e911";
}
.iconeq-ognisko-01:before {
  content: "\e912";
}
.iconeq-ogrod-01:before {
  content: "\e913";
}
.iconeq-parking-01:before {
  content: "\e914";
}
.iconeq-pingpong-01:before {
  content: "\e915";
}
.iconeq-plac_zabaw-01:before {
  content: "\e916";
}
.iconeq-radio-01:before {
  content: "\e917";
}
.iconeq-reczniki-01:before {
  content: "\e918";
}
.iconeq-rower-01:before {
  content: "\e919";
}
.iconeq-satelita-01:before {
  content: "\e91a";
}
.iconeq-sauna-01:before {
  content: "\e91b";
}
.iconeq-sejf:before {
  content: "\e91c";
}
.iconeq-silownia-01:before {
  content: "\e91d";
}
.iconeq-sprzet_plazowy-01:before {
  content: "\e91e";
}
.iconeq-suszarka-01:before {
  content: "\e91f";
}
.iconeq-sztucce:before {
  content: "\e920";
}
.iconeq-telewizor-01:before {
  content: "\e921";
}
.iconeq-termometr-01:before {
  content: "\e922";
}
.iconeq-wifi-01:before {
  content: "\e923";
}
.iconeq-wyzywienie-01:before {
  content: "\e924";
}
.iconeq-zwierzeta-01:before {
  content: "\e926";
}
.iconeq-parking-012:before {
  content: "\e927";
}
.iconeq-bike-01:before {
  content: "\e928";
}
.iconeq-tourist-voucher:before {
  content: "\e903";
}
.iconeq-clown-01:before {
  content: "\e929";
}
.iconeq-jacuzzi-01:before {
  content: "\e92a";
}
.iconeq-dancer-01:before {
  content: "\e92b";
}
.iconeq-grill-02:before {
  content: "\e92c";
}
.iconeq-ekspres-01:before {
  content: "\e925";
}
.iconeq-pralka-01:before {
  content: "\e92d";
}
.iconeq-zmywarka-01:before {
  content: "\e92e";
}
/*endregion*/

.equipment {
  &__wrapper {
    width: 100%;
    position: relative;
  }
  &__item {
    i {
      font-size: 18px;
      color: var(--color-theme-primary);
    }
    p {
      margin: 0;
      text-align: left;
    }
  }
}
