[data-module="slider"] {
	padding: 0px !important;
	position: relative !important;
}

.slider {
	&__wrapper {
		height: 80vh;
		width: 100%;
		position: relative;
		overflow: hidden;
		

		&:before {
			content: '';
			left: 0;
			top: 0;
			background: linear-gradient(0deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, .6) 100%);
			height: 160px;
			width: 100%;
			position: absolute;
			z-index: 2;
		}
	}

	&__image {
		height: 80vh;
		width: 100%;
		overflow: hidden;
		position: relative;

		&--item {
			position: relative;
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, .3);
			}
		}
	}

	&__text.title {
		position: absolute;
		top: 0;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		h1 {
			font-size: 58px;
			color: #fff;
			font-weight: 200;
			text-shadow: 4px 2px 2px rgb(0 0 0 / 30%);
		}

		h2 {
			color: #fff;
			font-size: 22px;
			text-shadow: 4px 2px 2px rgb(0 0 0 / 30%);
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	&:after {
		color: var(--color-theme-primary);
	}
}