.contactform {
    &__text {
        h3 {
            font-size: 36px;
            text-align: left;

            &:after {
                bottom: -60px;
                width: 120px;
                height: 120px;
                left: -40px;
                right: unset;
                opacity: .2;
            }
        }

        h4 {
            font-size: 28px;
            margin-bottom: 30px;
            margin-top: 0px;
            text-align: left;
        }
    }

    &__wrapper {
        .title {
            @media screen and (max-width:991px) {
                margin-top: 30px;
                margin-bottom: 15px;
            }
        }

        label {
            display: inline-block;
            cursor: pointer;
            position: relative;
            font-size: 14px;
            padding: 0 10px;

            input[type="checkbox"] {
                all: revert !important;
                width: 14px;
                height: 14px;
            }

            input[type="checkbox"]::before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em #ccc;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }
        }

        a {
            cursor: pointer;

            i {
                color: var(--color-theme-primary);
            }
        }
    }
}