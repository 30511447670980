
.photobackground{
	&__wrapper{
		height: 50vh;
		width: 100%;
		position: relative;
		overflow: hidden;
		&:after{
			content: '';
			left: 0;
			top: 0;
			background-color: rgba(#000,.1);
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: 1;
		}

	}
	&__image{
		&--item{
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	&__text.title{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		margin: 0 auto;
		h1{
			color: #fff;
		}
		h2{
			color: #fff;
		}
	}
}