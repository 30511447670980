@media screen and (max-width:991px) {
	[class^='container'] {
		padding: 0;
	}

	.swiper-button-next {
		display: none;
	}

	.swiper-button-prev {
		display: none;
	}

	.title {
		margin-bottom: 15px;

		h2,
		h4 {
			padding-left: 0 !important;

			&:after {
				content: unset !important;
			}
		}

	}

	.header {
		.col-4 {
			&:first-of-type {
				display: none;
			}
		}

		.menu {
			position: fixed;
			background: var(--color-theme-background-primary);
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			transition: all .4s ease-in-out;

			&__wrapper {
				flex-direction: column;
			}

			&__link {
				font-size: 18px;
			}

			&__hamburger {
				right: 10px;
			}
		}

		&__logo {
			width: auto;
		}
	}

	.slider,
	.hero {
		&__text.title {
			width: 100%;
			padding: 0;

			h1 {
				font-size: 32px;
				line-height: 32px;
				text-align: center;
			}

			h2 {
				font-size: 16px;
				text-align: center;
			}

			&:after {
				content: unset;
			}
		}
	}

	.textwithphoto {
		&__image {
			min-height: 400px;

		}

		&__text {
			left: unset !important;
			right: unset !important;

			.title {
				h3 {
					font-size: 28px;
				}

				h4 {
					font-size: 16px;
				}
			}
		}
	}

	.gallery {
		&__container {
			grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
			grid-auto-rows: 180px;
			overflow: hidden;
			height: auto;
			gap: 10px;
		}
	}

	.contactform {
		&>.row {
			&>* {
				padding: 0;
			}
		}
	}

	.footer {
		background-attachment: unset;

		.row {
			&>.col-md-4 {
				margin: 20px 0;

				&:last-of-type {
					align-items: center !important;
				}
			}
		}

		.title {
			margin-bottom: 15px;

			h4 {
				text-align: center;
			}
		}

		&__item {
			text-align: center;
		}
	}

	.header__logo img {
		width: auto;
		max-width: 180px;
	}
}

@media screen and (max-width:772px) {
	body {
		.footer {
			padding: 20px 0 60px;

			&__logo {
				width: auto;
				height: auto;
			}

			&__item:nth-child(1),
			&__item:nth-child(5) {
				display: none;
			}
		}

		.textwithphoto {
			&__image {
				&:after {
					display: none;
				}
			}

			&__text {
				margin: 15px;
				padding: 50px 30px;
				top: 40px;
			}
		}
	}
}

@media screen and (max-width:480px) {
	.credits {
		&__hide {
			display: none;
		}
	}
}